import React from "react";
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';
import StyleRoundedIcon from '@mui/icons-material/StyleRounded';
import MobileFooter from "./MobileFooter";
import {useTranslation} from "react-i18next";

export default function MachineDetails(props) {

    const {t} = useTranslation('common');
    const { promoCodes, machineData, vendAmount, selectedPromoCode, windowWidth, isPromosLoading, handleVendAmountChange, handlePromoSelection, nextStep, previousStep } = props;

    const columns  = [
        { field: 'code', headerName: t('Promo-Code'), width: 150 }
    ];

    const promoCodesList = promoCodes? promoCodes : []
    const rowSelectionModel = selectedPromoCode.id;

    const proceedToNext = () => {
        // Add validation?
        nextStep()
    } 

    return (
        <div className="step-container">            
            <div className="step-header">
                <StyleRoundedIcon />
                {t('Machine-Details')}
                <div className="wizard-step-info">{t('Step-Count-Detail', { current: props.currentStep, total: props.totalSteps })}</div>
            </div>
            <div className="step-content">
                <label>{t('Vend-Amount-Min-Max', {minAmount: machineData.minAmount, maxAmount: machineData.maxAmount})}</label>
                <input id="vendAmountInput" name="vendAmountInput" type='number' step={machineData.incrementByAmount} max={machineData.maxAmount} min={machineData.minAmount} value={vendAmount} onChange={handleVendAmountChange} className="form-control required"></input>                
                
                <div style={{marginTop: '20px'}}>
                    <label>{t('Select-Promo-Code')}</label>
                    <div style={{  height: 200, width: '100%' }}>
                        <DataGrid rows={promoCodesList} 
                            columns={columns} 
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            hideFooterRowCount
                            onRowSelectionModelChange={handlePromoSelection}
                            rowSelectionModel={rowSelectionModel}
                            loading={isPromosLoading}
                            slots={{
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <StyleRoundedIcon style={{ fontSize: "72px", fill: "rgba(103, 106, 108, 0.5)"}}/>
                                        <div style={{ color: "#676a6c", }}>
                                            {t('List-Empty', {data: '$t(Promo-Code)'})}
                                        </div>
                                    </Stack>
                                  ),
                            }}
                        />
                    </div>
                </div>

                <div className="step-footer">
                    { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={false}
                                disabledNext={!selectedPromoCode.hasOwnProperty('code')|| selectedPromoCode.code == "" || isNaN(parseFloat(vendAmount))|| parseFloat(vendAmount) <= 0}
                                onClickNext={proceedToNext}
                                onClickBack={previousStep}
                            />
                        ) : 
                        (
                            <div>
                                <button className='btn btn-default btn-block' onClick={previousStep}>{t('Go-Back')}</button>
                                <button className='btn btn-primary btn-block' disabled={!selectedPromoCode.hasOwnProperty('code')|| selectedPromoCode.code == "" || isNaN(parseFloat(vendAmount))|| parseFloat(vendAmount) <= 0}  onClick={proceedToNext}>{t('Next')}</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}