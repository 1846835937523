import React from 'react';
/* eslint react/prop-types: 0 */
import { Stepper, Step, StepLabel } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import clsx from 'clsx';

import LocalLaundryServiceRoundedIcon from '@mui/icons-material';
import StyleRoundedIcon from '@mui/icons-material';
import SummarizeRoundedIcon from '@mui/icons-material';
import ChangeCircleIcon from '@mui/icons-material';
import PublishedWithChangesIcon from '@mui/icons-material';
import {useTranslation} from "react-i18next";

const Nav = (props) => {
    const {t} = useTranslation('common');
    const theme = createTheme({
        palette: {
          primary: {
            main: orange[500],
          },
          secondary: {
            main: '#007bad',
          },
        },
      });

    /*
      const CustomStepIcon = (props) => {
        const { active, completed } = props;
    
        const stepIcons = {
          1: <LocalLaundryServiceRoundedIcon />,
          2: <StyleRoundedIcon />,
          3: <SummarizeRoundedIcon />,
          4: <ChangeCircleIcon />,
          5: <PublishedWithChangesIcon />,
        };
    
        return (
          <div>
            {stepIcons[(props.icon)]}
          </div>
        );
      };
*/
    return (
        <div className="wizard-nav">
            <div style={{ margin: 10 }}>
            <ThemeProvider theme={theme}>
                <Stepper activeStep={props.currentStep - 1} >
                    <Step>
                        <StepLabel>{t('Select-Machine')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('Set-Details')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('Summary')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('Processing')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('Result')}</StepLabel>
                    </Step>
                </Stepper>
            </ThemeProvider>
            </div>
        </div>
    );
};

export default Nav;