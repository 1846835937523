import React from 'react';
/* eslint react/prop-types: 0 */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useTranslation} from "react-i18next";

const MobileFooter = (props) => {
    const {t} = useTranslation('common');

    const {currentStep, disabledBack, disabledNext, onClickNext, onClickBack} = props;

    const theme = createTheme({
        palette: {
          primary: {
            main: orange[500],
          },
          secondary: {
            main: '#007bad',
          },
        },
      });

    return (
        <div className="wizard-mobile-footer">
            <ThemeProvider theme={theme}>
              <MobileStepper
                  variant="dots"
                  steps={5}
                  position="static"
                  activeStep={currentStep-1}
                  sx={{ maxWidth: 400, flexGrow: 1 }}
                  nextButton={
                      <Button size="small" onClick={onClickNext} disabled={disabledNext}>
                      {currentStep>4? t('Finish') : t('Next')} <KeyboardArrowRight />
                      </Button>
                  }
                  backButton={
                      <Button size="small" onClick={onClickBack} disabled={disabledBack}>
                          <KeyboardArrowLeft />  {currentStep>4? t('New-Request') : t('Go-Back')}
                      </Button>
                  }
              />
            </ThemeProvider>
        </div>
    );
};

export default MobileFooter;