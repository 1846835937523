import React from "react";

export default function Dropdown(props) {
    const options = props.options;
    const value = props.value;
    const onChange = props.onChange;

    if (!options) {
        return <div>No Sites yet...</div>
    }

    return (
        <div>
            
            <select className="form-control m-b" value={value} onChange={onChange}>

                {options.map((option) => (

                <option key={option.value} value={option.value} detail={option.detail}>{option.label}</option>

                ))}

            </select>
        </div>
    );
}