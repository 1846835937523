import React, { Component } from "react";

import SquareLoader from "react-spinners/SquareLoader";
import PuffLoader from "react-spinners/PuffLoader";

export default function Loader({ loading }) {
    const color = "#F57C20";
    return (
        <div>
            <div className="squareloader">
                {/* <SquareLoader
                    color={"white"}
                    loading={loading}
                    size={32}
                /> */}
                <PuffLoader color={color}
                    loading={loading}
                    size={150} 
                />
            </div>
        </div>
    );
  }