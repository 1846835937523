import React, { Component, StrictMode } from "react";
import ReactDOM from 'react-dom';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
/*
* This loads the translations files which is used by the i18next.
* If new languages are added, please add the file path below and make sure to load it in the i18next.use section below
* */
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_lt from "./translations/lt/common.json";
import common_es from "./translations/es/common.json";
import common_de from "./translations/de/common.json";

import RemoteStartRoot from "./remoteStart/RemoteStartRoot";
import AlertsAdminRoot from "./alerts/management/AlertsAdminRoot";
import Alerts from "./alerts/Alerts";

class Main extends Component {
    render() {
        return (
            <div>
                {/* <RemoteStartRoot /> */}
                <AlertsAdminRoot />
            </div>
        );
    }
}

i18next.use(new LanguageDetector(null, { lookupLocalStorage: "selectedLang" })).init({
    supportedLngs: ["en", "es", "lt", "fr"],
    fallbackLng: "en",
    interpolation: { skipOnVariables: false, escapeValue: false },  // React already does escaping
    resources: {
        en: {
            common: common_en               // 'common_en' is declared at the top
        },
        fr: {
            common: common_fr               // 'common_fr' is declared at the top
        },
        lt: {
            common: common_lt               // 'common_lt' is declared at the top
        },
        es: {
            common: common_es               // 'common_lt' is declared at the top
        },
        de: {
            common: common_de               // 'common_lt' is declared at the top
        },
    },
});

if(document.getElementById('react-mountpoint')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <Main/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-mountpoint')
    );
}

if(document.getElementById('react-mountpoint-remotestart')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <RemoteStartRoot/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-mountpoint-remotestart')
    );
}

if(document.getElementById('react-alert-mountpoint')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <Alerts/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-alert-mountpoint')
    );
}