import React, { useEffect, useState } from "react";
import Dropdown from "../common/Dropdown";
import MobileFooter from "./MobileFooter";
import { DataGrid } from '@mui/x-data-grid';

import Stack from '@mui/material/Stack';


import LocalLaundryServiceRoundedIcon from '@mui/icons-material/LocalLaundryServiceRounded';
import {useTranslation} from "react-i18next";

export default function MachineSelection(props) {

    const {t} = useTranslation('common');

    const { handleSiteDropdownChange, siteList, machineList, selectedSiteId, windowWidth, isMachinesLoading, nextStep, handleMachineSelection, selectedMachine } = props
    const columns  = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'uuid', headerName: 'uuid', width: 150 },
        { field: 'siteName', headerName: 'Site Name', width: 150 },
        { field: 'siteIdentifier', headerName: 'siteIdentifier', width: 150 },
        { field: 'zone', headerName: 'Zone', width: 150 },
        { field: 'name', headerName: t('Name'), flex: 1 },
        { field: 'type', headerName: t('Type'), flex: 1 },
        { field: 'brand', headerName: 'Brand', width: 150 },
        { field: 'model', headerName: 'Model', width: 150 },
        { field: 'defaultAmount', headerName: 'defaultAmount', width: 150 },
        { field: 'minAmount', headerName: 'minAmount', width: 150 },
        { field: 'maxAmount', headerName: 'maxAmount', width: 150 },
        { field: 'incrementByAmount', headerName: 'incrementByAmount', width: 150 },
      ];

    let columnVisibilityModel = {       
        id: false,                 
        uuid: false,
        siteName: false,
        siteIdentifier: false,
        zone: false,
        type: windowWidth >= 769,
        brand: false,
        model: false,
        defaultAmount: false,
        minAmount: false,
        maxAmount: false,
        incrementByAmount: false,
    }

    const proceedToNext = () => {
        // Add validation?
        nextStep()
    } 

    const rowSelectionModel = selectedMachine? selectedMachine.id : '';

    return (
        <div className="step-container">
            <div className="step-header">
                <LocalLaundryServiceRoundedIcon />
                {t('Machine-Selection')}
                <div className="wizard-step-info"> {t('Step-Count-Detail', { current: props.currentStep, total: props.totalSteps })} </div>
            </div>
            <div className="step-content">
                <label>{t('Site')}:</label>
                <Dropdown options={siteList} onChange={handleSiteDropdownChange} value={selectedSiteId}/>

                <div>
                    <label>{t('Select-Machine-From-Table')}</label>
                    <div style={{ height: 372, width: '100%' }}>
                        <DataGrid rows={machineList} 
                            columns={columns} 
                            columnVisibilityModel={columnVisibilityModel}
                            autoPageSize
                            onRowSelectionModelChange={handleMachineSelection}
                            rowSelectionModel={rowSelectionModel}
                            loading={isMachinesLoading}
                            hideFooterSelectedRowCount
                            hideFooterRowCount
                            slots={{
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <LocalLaundryServiceRoundedIcon style={{ fontSize: "72px", fill: "rgba(103, 106, 108, 0.5)"}}/>
                                        <div style={{ color: "#676a6c", }}>
                                            {t('No-Machines-Available')}
                                        </div>
                                    </Stack>
                                  ),
                            }}
                            sx={{
                                '& .MuiTablePagination-displayedRows': {
                                  display: 'none',
                                },
                            }}
                        />
                    </div>
                </div>

                { selectedMachine.hasOwnProperty('name') && selectedMachine.name != '' ?
                    (<div style={{marginTop: '20px'}}>
                            <label style={{marginRight: '20px'}}>{t('Selected')}</label>
                            {selectedMachine.name} ({selectedMachine.brand}-{selectedMachine.type})
                        </div>
                    ) : (
                        <div style={{padding: '24px'}}></div>
                    )
                }
                <div className="step-footer">
                    { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={true}
                                disabledNext={!selectedMachine.hasOwnProperty('name')}
                                onClickNext={proceedToNext}
                            />
                        ) : 
                        (
                            <button className='btn btn-primary btn-block' disabled={!selectedMachine.hasOwnProperty('name')} onClick={proceedToNext}>{t('Next')}</button>
                        )
                        }
                    
                </div>
            </div>
        </div>
    );
}