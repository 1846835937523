import React, { useEffect, useState, useRef } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {useTranslation} from "react-i18next";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import AlertsListScreen from "./AlertsListScreen";
import AddEditScreen from "./AddEditScreen";
import './alertsadmin.css'

export default function AlertsAdminRoot() {

    const {t, i18n} = useTranslation('common');

    const [alertsList, setAlertsList] = useState([])
    const [alertsListLoading, setAlertsListLoading] = useState(false)
    const [selectedAlert, setSelectedAlert] = useState({})
    const [isAddEditScreen, setAddEditScreen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [countryList, setCountryList] = useState([])

    const theme = createTheme({
        palette: {
          primary: {
            main: "#ff8c00"
          },
          secondary: {
            main: "#4caf50",
          },
        },
      });

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };

    // For retrieving alerts on load
    useEffect(() => {
        retrieveAlerts();
    }, [])

    // For retrieving country list on load
    useEffect(() => {
        fetch("/api/admin/alerts/countryList", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => response.json())
        .then(
            res => {
                setCountryList(res)

                if(res.length == 0) {
                    toast.warn("Country list is empty !", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        )
        .catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
            toast.error("An error occurred while fetching the country list.", {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }, [])

    // For resizing
    useEffect(() => {
        const handleResizeWindow = () => setWindowWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, [])

    const handleListScreenAddBtnClick = () => {
        setAddEditScreen(true)
    }

    const handleExitAddEditScreen = () => {
        setSelectedAlert({})
        setAddEditScreen(false)
    }

    const containerRef = useRef(null);
    const paperStyle = {
        overflow: "hidden",
        padding: "20px",
    }
    const boxStyle = {
        border: 0,
        minHeight: 1/2,
        maxHeight: 3/4
    }

    const handleFormSubmission  = (formData) => {
        fetch('/api/admin/alerts/add', {
            method: 'POST',
            body: formData,
        })
        .then((result) => {
            if(result.status == 200){
                toast.success("An alert was successfully added.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.warn("An error occurred while processing the request", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(result)
            }

        })
        .catch((err) => {
            console.error('There was a problem with the Form Submission operation:', err);
            toast.error("An error occurred while processing the request", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .finally(() => {
            setSelectedAlert({})
            retrieveAlerts();
            setAddEditScreen(false)
        });

    }

    const handleDeleteAlert = (formData) => {
        fetch('/api/admin/alerts/delete', {
            method: 'POST',
            body: formData,
        })
        .then((result) => {
            if(result.status == 200){
                toast.success("An alert was successfully added.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.warn("An error occurred while processing the request", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(result)
            }

        })
        .catch((err) => {
            console.error('There was a problem with the Form Submission operation:', err);
            toast.error("An error occurred while processing the request", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .finally(() => {
            setSelectedAlert({})
            retrieveAlerts();
            setAddEditScreen(false)
        });

    }

    const retrieveAlerts = () => {
        setAlertsListLoading(true)
        fetch("/api/admin/alerts", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => response.json())
        .then(
            res => {
                setAlertsList(res)
            }
        )
        .catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
            toast.error("An error occurred while fetching the alerts list.", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .finally(() => {
            setAlertsListLoading(false)
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <div id="adminAlertsRoot">
                {alertsListLoading && <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>}
                {/* <h1>{t('alerts.title')}</h1> */}

                <Paper ref={containerRef} sx={paperStyle}>
                    <Slide direction="right" in={!isAddEditScreen} mountOnEnter unmountOnExit container={containerRef.current}>
                        <Box sx={boxStyle}>
                            <AlertsListScreen
                                windowWidth={windowWidth}
                                alertsList={alertsList}
                                alertsListLoading={alertsListLoading}
                                handleAddBtnClick={handleListScreenAddBtnClick}
                                handleDeleteAlert={handleDeleteAlert}
                                selectedAlert={selectedAlert}
                                setSelectedAlert={setSelectedAlert}
                            />
                        </Box>
                    </Slide>

                    <Slide direction="left" in={isAddEditScreen} mountOnEnter unmountOnExit container={containerRef.current}>
                        <Box sx={boxStyle}>
                            <AddEditScreen
                                windowWidth={windowWidth}
                                selectedAlert={selectedAlert}
                                countryList={countryList}
                                returnCallback={handleExitAddEditScreen}
                                handleFormSubmission={handleFormSubmission}
                            />
                        </Box>
                    </Slide>
                </Paper>
                <ToastContainer />
            </div>
        </ThemeProvider>
    );
  }