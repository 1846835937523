import React from "react";
import Loader from "../common/Spinner";
import MobileFooter from "./MobileFooter";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {useTranslation} from "react-i18next";

export default function Processing(props) {
    const { isProcessingForm, windowWidth } = props;
    const {t} = useTranslation('common');
    // if(!isProcessingForm && currentStep==4) {
    //     nextStep()
    // }
    return (
        <div className="step-container">
            <div className="step-header">
                < ChangeCircleIcon />
                {t('Processing')}
                <div className="wizard-step-info">{t('Step-Count-Detail', { current: props.currentStep, total: props.totalSteps })}</div>
            </div>
            <div className="step-content">
                <Loader />
                <p className='text-center'>{t('Processing-In-Progress')}</p>
                <div >
                { windowWidth < 769 &&
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={true}
                                disabledNext={true}
                            />
                        ) }
                </div>
            </div>
        </div>
    );
}