import React from "react";
import MobileFooter from "./MobileFooter";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {useTranslation} from "react-i18next";

export default function Result(props) {

    const {t} = useTranslation('common');
    const { processingResponse, selectedSiteId, firstStep, windowWidth, restartForm } = props;

    const goToFirstStep = () => {
        firstStep()
        restartForm()
    } 

    const redirect = () => {
        window.location.replace("/machines/?siteId="+selectedSiteId);
    }

    return (
        <div className="step-container">
            <div className="step-header">
                <PublishedWithChangesIcon />
                {t('Result')}
                <div className="wizard-step-info">{t('Step-Count-Detail', { current: props.currentStep, total: props.totalSteps })}</div>
            </div>
            <div className="step-content">
                <div>
                    <h3 className={'text-center'}>{t('Processing-Completed')}</h3>
                    <div className={'text-center'}>{t('Processing-Completed-Description')}</div>

                    {/* {"code":200,"info":"Success","customerReference":"ZD3-PE8","promotionCode":"5 Dudley Street Laundry Room","siteName":"Success","result":"Dryer #3","machineName":"0","cycleTime":""} */}

                    { processingResponse.code == 200 ? (
                        <div className="processing-response">
                            <div>
                                <div className="summary-detail-label">{t('Customer-Reference')}</div>
                                <div className="summary-detail-value">{processingResponse.customerReference}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">{t('Promotion-Code')}</div>
                                <div className="summary-detail-value">{processingResponse.promotionCode}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">{t('Site-Name')}</div>
                                <div className="summary-detail-value">{processingResponse.siteName}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">{t('Machine-Name')}</div>
                                <div className="summary-detail-value">{processingResponse.machineName}</div>
                            </div>
                            { processingResponse.result == "Success" ?
                                (
                                    <div>
                                        <div className="summary-detail-label">{t('Result')}</div>
                                        <div className="summary-detail-value" style={{color: "green"}}>{processingResponse.result}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="summary-detail-label">{t('Result')}</div>
                                        <div className="summary-detail-value" style={{color: "red"}}>{processingResponse.result}</div>
                                        <div className="summary-detail-value">
                                            {t('Summary-Credit-Error')} <a href={'/machines/status?siteIdentifier='+processingResponse.siteIdentifier}>{t('Machine-Status-Link')}</a>
                                        </div>
                                    </div>
                                )
                            }
                            <div>
                                <div className="summary-detail-label">{t('Cycle-Time')}</div>
                                <div className="summary-detail-value">{processingResponse.cycleTime}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {t('Generic-Error-Message')}
                        </div>
                    )}
                </div>

                <div className="step-footer">
                { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={false}
                                disabledNext={false}
                                onClickBack={goToFirstStep}
                                onClickNext={redirect}
                            />
                        ) : 
                        (<div>
                            <button className='btn btn-default btn-block' onClick={goToFirstStep}>{t('Start-New-Request')}</button>
                            <button className='btn btn-success btn-block' onClick={redirect}>{t('Finish')}</button>
                        </div>
                        )}
                </div>
            </div>
        </div>
    );
}