import React from "react";
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import MobileFooter from "./MobileFooter";
import {useTranslation} from "react-i18next";

export default function Summary(props) {

    const {t} = useTranslation('common');
    const { machine, vendAmount, promoCode, windowWidth, nextStep, previousStep, handleFormSubmission } = props;

    const proceedToNext = () => {
        nextStep()
        handleFormSubmission()
    } 

    return (
        <div className="step-container">
            <div className="step-header">
                <SummarizeRoundedIcon />
                {t('Summary')}
                <div className="wizard-step-info">{t('Step-Count-Detail', { current: props.currentStep, total: props.totalSteps })}</div>
            </div>
            <div className="step-content">
                <p className='text-center'>{t('Summary-Please-Review')}</p>
                <div >
                    <div>
                        <div className="summary-detail-label">{t('Machine-Name')}</div>
                        <div className="summary-detail-value">{machine.name}</div>
                    </div>
                    <div>
                        <div className="summary-detail-label">{t('Vend-Amount')}</div>
                        <div className="summary-detail-value">{Number(vendAmount).toFixed(2)} </div>
                    </div>
                    <div>
                        <div className="summary-detail-label">{t('Promo-Code')}</div>
                        <div className="summary-detail-value">{promoCode.code}</div>
                    </div>

                    <div className="step-footer">
                    { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={false}
                                disabledNext={false}
                                onClickNext={proceedToNext}
                                onClickBack={previousStep}
                            />
                        ) : 
                        (
                            <div>
                                <button className='btn btn-default btn-block' onClick={previousStep}>{t('Go-Back')}</button>
                                <button className='btn btn-primary btn-block' onClick={proceedToNext}>{t('Submit')}</button>
                            </div>
                        )}   
                    </div>
                </div>
            </div>
        </div>
    );
}