import React,  { useState, useRef, useEffect } from "react";

import { Button } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import MailIcon from '@mui/icons-material/Mail';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import * as dayjs from 'dayjs';
import {useTranslation} from "react-i18next";

export default function AlertsListScreen(props) {
    const {t} = useTranslation('common');
    const { alertsListLoading, alertsList, windowWidth, handleAddBtnClick, selectedAlert, setSelectedAlert, handleDeleteAlert, } = props;

    const getIconForAlertType = (params) => {
        let type = params.value;
        const color = "primary";
        switch(type) {
            case 'NEW_FEATURE':
                return <AnnouncementIcon color={color} />;
            case 'OUTAGE':
                return <CrisisAlertIcon  color={color} />;
            case 'SYSTEM_MAINTENANCE':
                return <EngineeringIcon  color={color} />;
            default:
                return <MailIcon  color={color} />;
        }
    }

    const getDisplayTime = (params) => {
        const time = params.value
        return time && time!=''? dayjs(parseInt(time)).format('DD/MM/YYYY') : ''
    }

    const columns  = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'type',
            headerName: t('Type'),
            flex: 0.25 ,
            renderCell: getIconForAlertType
        },
        { field: 'summary', headerName: t('Summary'), flex: 1.75, },
        {
            field: 'timestamp',
            headerName: t('Time'),
            flex: 1,
            valueFormatter: getDisplayTime
        },
        {
            field: 'expiry',
            headerName: t('Expiry'),
            flex: 1,
            valueFormatter: getDisplayTime
        },
        { field: 'modifiedBy', headerName: t('Modified-By'), flex: 1, },
        { field: 'content', headerName: t('Content'), flex: 2.5, },
        { field: 'targetCountry', headerName: t('Country'), flex: 0.5, },
        { field: 'targetGateway', headerName: t('Gateway'), flex: 0.5, },
        { field: 'targetProducts', headerName: t('Products'), flex: 0.5, },
      ];

    let columnVisibilityModel = {
        id: false,
        timestamp: windowWidth >= 350,
        expiry: windowWidth >= 350,
        modifiedBy: windowWidth >= 1200,
        content: windowWidth >= 769,
        targetCountry: windowWidth >= 1200,
        targetGateway: windowWidth >= 1200,
        targetProducts: windowWidth >= 1200,
    }

    const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
    const [selectedAlertModel, setSelectedAlertModel] = useState(JSON.stringify(selectedAlert) !== '{}' ? selectedAlert.id :[])

    const handleDeleteClose = () => {
        setDeleteDialogVisible(false);
    };

    const handleDeleteConfirm = () => {
        const csrfKey = document.getElementById("csrftoken_").name
        const csrfValue = document.getElementById("csrftoken_").value
        const csrfParamsKey =document.getElementById("params_").name
        const csrfParamsValue =document.getElementById("params_").value

        const formData = new FormData();
        formData.append("id", selectedAlert.id)
        formData.append(csrfKey, csrfValue)
        formData.append(csrfParamsKey, csrfParamsValue)

        handleDeleteAlert(formData)
        setDeleteDialogVisible(false);
    };

    const handleClickAway = () => {
        setSelectedAlert({})
        setSelectedAlertModel([]);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <div className="alertsListScreen">
            <div id="adminAlertsHeader" >
                    <div className="title">{t('Alerts-Management')}</div>
                    <Button disabled={alertsListLoading} variant="contained" size="small" onClick={handleAddBtnClick}>{JSON.stringify(selectedAlert) !== '{}' ? t('Edit') : t('Add')}</Button>
                    <Button disabled={JSON.stringify(selectedAlert) === '{}'} className="deleteAlertBtn" color='error' variant="contained" size="small" onClick={(e) => setDeleteDialogVisible(true)}>{t('Delete')}</Button>
                </div>
                <div id="adminAlertsContent">

                    <div style={{ minHeight: '371px' , maxHeight: 800, width: '100%' }}>
                        <DataGrid rows={alertsList}
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            paginationModel={{ page: 0, pageSize: 10 }}
                            pageSizeOptions={[10, 20, 50]}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setSelectedAlertModel(newRowSelectionModel);

                                if(newRowSelectionModel.length > 0){
                                    const selectedRowData = alertsList.filter((row) =>
                                        newRowSelectionModel[0] == row.id
                                    );
                                    setSelectedAlert(selectedRowData[0])
                                } else {
                                    setSelectedAlert({})
                                }

                            }}
                            rowSelectionModel={selectedAlertModel}
                            loading={alertsListLoading}
                            slots={{
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <NotificationsNoneIcon style={{ fontSize: "72px", fill: "rgba(103, 106, 108, 0.5)"}}/>
                                        <div style={{ color: "#676a6c", }}>
                                            {t('No-Alerts-Available')}
                                        </div>
                                    </Stack>
                                    ),
                            }}

                        />
                        <Dialog
                            open={isDeleteDialogVisible}
                            onClose={handleDeleteClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="deleteAlertDialog"
                        >
                            <DialogTitle id="alert-dialog-title">
                            {"Delete the selected alert?"}
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t('Click-Confirm-Button')}
                                <span>{selectedAlert.summary}</span>
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleDeleteClose}  className="cancelBtn" color='inherit'>{t('Cancel')}</Button>
                            <Button onClick={handleDeleteConfirm} className="confirmBtn" autoFocus>
                                {t('Confirm')}
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
        </div>
        </ClickAwayListener>
    );
}