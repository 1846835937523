// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#remoteStartRoot {
    background: white;
    padding: 20px;
    margin: 20px 0;
    margin-bottom: 60px;
}

.wizard-nav {
    background: white;
    margin-bottom: 30px;
}

.step-wizard {
    background: white;
}
.step-container {
    width: 100%;
}
.step-header {
    background: orange;
    padding: 20px;
    color: white;
    text-shadow: 1px 1px 2px #fe7b00;
    font-weight: bold;
    font-size: 16px;
}
.step-header svg{
    filter: drop-shadow(1px 1px 1px rgb(254 123 0 / 0.7));
}

.step-content {
    border: 1px solid lightgray;
    border-top: 0px;
    padding: 20px;
}
.wizard-step-info {
    float: right;
    font-weight: bold;
}
.summary-detail-label {
    font-size: 14px;
    font-weight: bold;
    margin-left: 20%;
}
.summary-detail-value {
    font-size: 14px;
    margin-left: 30%;
    padding-bottom: 25px;
}
.step-footer {
    margin-top: 20px;
}
.MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle, .MuiStepLabel-label, 
.MuiTablePagination-selectLabel, .MuiInputBase-colorPrimary, .MuiTablePagination-displayedRows,
.MuiDataGrid-selectedRowCount {
    color: #676a6c !important;
}

.MuiTablePagination-root p{
    margin: inherit;
}

.MuiDataGrid-row.Mui-selected {
    background-color: rgba(255,165,0, 0.4) !important; 
}`, "",{"version":3,"sources":["webpack://./src/main/resources/reactJs/remoteStart/remotestart.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,gCAAgC;IAChC,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,qDAAqD;AACzD;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,aAAa;AACjB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;;;IAGI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iDAAiD;AACrD","sourcesContent":["#remoteStartRoot {\n    background: white;\n    padding: 20px;\n    margin: 20px 0;\n    margin-bottom: 60px;\n}\n\n.wizard-nav {\n    background: white;\n    margin-bottom: 30px;\n}\n\n.step-wizard {\n    background: white;\n}\n.step-container {\n    width: 100%;\n}\n.step-header {\n    background: orange;\n    padding: 20px;\n    color: white;\n    text-shadow: 1px 1px 2px #fe7b00;\n    font-weight: bold;\n    font-size: 16px;\n}\n.step-header svg{\n    filter: drop-shadow(1px 1px 1px rgb(254 123 0 / 0.7));\n}\n\n.step-content {\n    border: 1px solid lightgray;\n    border-top: 0px;\n    padding: 20px;\n}\n.wizard-step-info {\n    float: right;\n    font-weight: bold;\n}\n.summary-detail-label {\n    font-size: 14px;\n    font-weight: bold;\n    margin-left: 20%;\n}\n.summary-detail-value {\n    font-size: 14px;\n    margin-left: 30%;\n    padding-bottom: 25px;\n}\n.step-footer {\n    margin-top: 20px;\n}\n.MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle, .MuiStepLabel-label, \n.MuiTablePagination-selectLabel, .MuiInputBase-colorPrimary, .MuiTablePagination-displayedRows,\n.MuiDataGrid-selectedRowCount {\n    color: #676a6c !important;\n}\n\n.MuiTablePagination-root p{\n    margin: inherit;\n}\n\n.MuiDataGrid-row.Mui-selected {\n    background-color: rgba(255,165,0, 0.4) !important; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
